@font-face {
  font-family: "NotoSansTC";
  src: local("NotoSansTC"), url("../fonts/NotoSansTC-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: "NotoSansTC", sans-serif;
  font-size: 15px;
}

a {
  text-decoration: none;
}

.pageSettings {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.navbar {
  padding: 0;
  background-color: #000;
}
.navbar .navbar-brand,
.navbar .nav-link {
  font-size: clamp(14px, 2vw, 18px);
  color: #fff;
  font-weight: 600;
}
.navbar .navbar-brand.active,
.navbar .nav-link.active {
  color: #fff;
}
.navbar .navbar-brand {
  display: flex;
  align-items: center;
  margin-right: 100px;
  font-size: clamp(20px, 3vw, 28px);
  font-weight: 400;
  font-family: "BebasNeue", sans-serif;
}
.navbar .navbar-brand img {
  width: 60px;
  aspect-ratio: 1/1;
  object-fit: cover;
}
@media (width < 576px) {
  .navbar .navbar-brand {
    margin-right: 0px;
  }
}

.homeFooter,
.footer {
  min-height: 50px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #000;
  font-size: clamp(14px, 2vw, 18px);
  color: #fff;
}
.homeFooter .ipad,
.footer .ipad {
  width: 100%;
  position: relative;
  text-align: start;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  font-weight: 600;
}
.homeFooter .ipad .footerLogo,
.footer .ipad .footerLogo {
  height: 100%;
  position: absolute;
  right: 0;
  top: 18px;
  opacity: 0.5;
}
.homeFooter .ipad .item,
.footer .ipad .item {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.homeFooter .ipad .item img,
.footer .ipad .item img {
  width: 23px;
  height: 23px;
}
.homeFooter .ipad .contactBox,
.footer .ipad .contactBox {
  padding: 0 15px 0;
}
.homeFooter .ipad .contactBox .phone,
.footer .ipad .contactBox .phone {
  margin-bottom: 20px;
}
.homeFooter .ipad .title,
.footer .ipad .title {
  margin-bottom: 20px;
  font-size: 18px;
}

@media (width < 769px) {
  .homeFooter {
    min-height: auto;
    padding: 30px 16px;
  }
  .footer {
    padding: 0 12px;
    justify-content: start;
    font-size: clamp(12px, 2vw, 14px);
  }
}
.mainPage {
  flex: 1 0 auto;
  position: relative;
}

.homePage .mainPage {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.homePage .content-wrap {
  width: 85%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.homePage .content {
  text-align: center;
  font-weight: 600;
}
.homePage .content .title {
  font-size: clamp(38px, 7vw, 68px);
  line-height: clamp(48px, 9vw, 88px);
}
.homePage .content .subtitle {
  margin-top: 32px;
  font-size: clamp(12px, 3vw, 20px);
  letter-spacing: -1px;
}
.homePage .content .contactBtn {
  width: 85%;
  padding: clamp(12px, 2vw, 14px);
  margin-top: 32px;
  position: relative;
  font-size: clamp(14px, 3vw, 20px);
  font-weight: 600;
  color: #fff;
  background-color: #000;
  border-radius: 50px;
}
.homePage .content .contactBtn a {
  color: #fff;
}
.homePage .content .contactBtn i {
  position: absolute;
  right: 19%;
  top: 15%;
  font-size: 28px;
}
.homePage .content .services,
.homePage .content .case {
  padding: 50px 16px;
}
.homePage .content .services .title,
.homePage .content .case .title {
  font-size: 26px;
}
.homePage .content .services .subtitle,
.homePage .content .case .subtitle {
  margin-top: 10px;
}
.homePage .content .services {
  background-size: contain;
  color: #fff;
}
.homePage .content .services .contactBtn {
  width: 75%;
  background: transparent;
  border: 3px solid #fff;
}
.homePage .content .services .contactBtn a {
  color: #fff;
}
.homePage .content .services .contactBtn i {
  right: 22%;
}
.homePage .content .grayBlock {
  height: 80px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #242a2e;
  color: #fff;
}
.homePage .whiteBlock {
  height: 10px;
  background-color: #fff;
}
.homePage .toTop {
  justify-content: end;
  font-size: 18px;
}
@media (width < 576px) {
  .homePage .mainPage {
    margin-top: 10px;
  }
  .homePage .content .contactBtn i {
    right: 12%;
    top: 8%;
  }
  .homePage .content .services .contactBtn i {
    right: 18%;
  }
}

.content .case {
  background-color: #fafafa;
}
.content .case .item {
  padding: 16px 16px 24px;
  background-color: #f5f5f5;
  border-radius: 12px;
}
.content .case .item + .item {
  margin-top: 16px;
}
.content .case .titleBox {
  margin-bottom: 50px;
}
.content .case .caseImg,
.content .case .ServicesImg {
  width: 100%;
  display: flex;
  aspect-ratio: 1/0.6;
  background-color: #fff;
  border-radius: 12px;
}
.content .case .caseImg .img,
.content .case .ServicesImg .img {
  width: 90%;
  height: 90%;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.content .case .caseTitle {
  font-size: 24px;
  margin-top: 20px;
}
.content .case .caseText {
  margin-top: 20px;
  font-size: clamp(12px, 2vw, 14px);
}

.aboutPage .ipadBK,
.contactUsPage .ipadBK {
  height: 25%;
}
.aboutPage .mainPage,
.contactUsPage .mainPage {
  padding: 30px 70px 30px 0px;
}
.aboutPage .content,
.contactUsPage .content {
  height: 100%;
  position: relative;
  background-color: #f1f1f1;
}
.aboutPage .contactData,
.contactUsPage .contactData {
  height: 100%;
  margin-left: clamp(0px, 12vw, 100px);
  display: flex;
  align-items: center;
}
.aboutPage .contactData .title,
.contactUsPage .contactData .title {
  margin-bottom: clamp(50px, 7vw, 60px);
  font-size: clamp(24px, 6vw, 50px);
  font-weight: 600;
  line-height: 1.2;
  word-break: keep-all;
}
.aboutPage .contactData .subtitle,
.contactUsPage .contactData .subtitle {
  margin-top: clamp(50px, 7vw, 60px);
  margin-bottom: clamp(50px, 7vw, 60px);
  font-size: clamp(16px, 3vw, 20px);
  font-weight: 600;
  word-break: keep-all;
}
@media (width < 769px) {
  .aboutPage .contactData .subtitle .titleBox,
  .contactUsPage .contactData .subtitle .titleBox {
    display: flex;
    flex-direction: column;
  }
}
.aboutPage .contactData .dataInfo,
.contactUsPage .contactData .dataInfo {
  width: 60%;
}
.aboutPage .contactData .BKImg,
.contactUsPage .contactData .BKImg {
  width: 40%;
  height: 90%;
  position: relative;
}
.aboutPage .contactData .BKImg img,
.contactUsPage .contactData .BKImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  right: -50px;
  object-fit: cover;
}
.aboutPage .contactData .item .ipad,
.contactUsPage .contactData .item .ipad {
  display: inline-block;
}
.aboutPage .contactData .textBox-wrap .description,
.contactUsPage .contactData .textBox-wrap .description {
  width: 100%;
}
.aboutPage .contactData .textBox-wrap .title,
.contactUsPage .contactData .textBox-wrap .title {
  font-size: 28px;
  word-break: keep-all;
}
.aboutPage .contactData .textBox-wrap .textBox,
.contactUsPage .contactData .textBox-wrap .textBox {
  line-height: 2;
}
@media (width < 769px) {
  .aboutPage .contactData .textBox-wrap .textBox,
  .contactUsPage .contactData .textBox-wrap .textBox {
    margin-right: 0px;
  }
}
@media (width < 769px) {
  .aboutPage .mainPage,
  .contactUsPage .mainPage {
    padding: 0;
  }
  .aboutPage .content .contactData,
  .contactUsPage .content .contactData {
    height: auto;
    margin-left: 0;
  }
  .aboutPage .content .contactData .title,
  .aboutPage .content .contactData .description,
  .contactUsPage .content .contactData .title,
  .contactUsPage .content .contactData .description {
    margin-top: clamp(50px, 7vw, 60px);
    text-align: center;
  }
  .aboutPage .content .dataInfo,
  .contactUsPage .content .dataInfo {
    width: 100%;
    margin-bottom: 50px;
  }
  .aboutPage .content .BKImg,
  .contactUsPage .content .BKImg {
    display: none;
  }
}

.contactUsPage .mainPage {
  padding: 30px 0px 30px 70px;
}
.contactUsPage .content .ipadBK .textBox .text2 {
  margin-bottom: 40px;
}
.contactUsPage .contactData {
  margin-left: 0;
}
.contactUsPage .contactData .title .en {
  white-space: nowrap;
}
.contactUsPage .contactData .BKImg {
  height: 55%;
}
.contactUsPage .contactData .BKImg img {
  left: -90px;
  right: 0;
}
@media (width < 769px) {
  .contactUsPage .mainPage {
    padding: 0;
  }
  .contactUsPage .content .contactData {
    margin-right: 0;
    text-align: center;
  }
}

.ipadBK {
  position: relative;
  height: 11%;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ipadBK .blackBlock {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.ipadBK .textBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  color: #fff;
}
.ipadBK .textBox .text {
  margin-bottom: 5px;
  font-size: clamp(18px, 4vw, 28px);
  font-weight: 600;
}
.ipadBK .textBox .text2 {
  font-size: clamp(14px, 3vw, 18px);
}

.servicesPage .content {
  height: 100%;
}
.servicesPage .contentData {
  height: inherit;
}
.servicesPage .contentData .BKImg {
  width: 20%;
  min-width: 270px;
  height: inherit;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.servicesPage .contentData .BKImg .blackBlock {
  width: 100%;
  height: inherit;
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  z-index: 0;
}
.servicesPage .contentData .BKImg .textBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  color: #fff;
  z-index: 1;
  white-space: nowrap;
}
.servicesPage .contentData .BKImg .textBox .title {
  font-size: clamp(28px, 7vw, 58px);
  font-weight: 600;
}
.servicesPage .contentData .BKImg .textBox .text {
  font-size: clamp(16px, 4vw, 22px);
}
.servicesPage .contentData .dataInfo {
  width: 80%;
  display: flex;
}
@media (width < 769px) {
  .servicesPage .contentData .dataInfo {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 50px;
  }
}
.servicesPage .contentData .dataInfo .btnBox {
  display: flex;
  align-items: center;
  padding: 8px;
}
@media (width < 768px) {
  .servicesPage .contentData .dataInfo .btnBox {
    display: none;
  }
}
.servicesPage .contentData .dataInfo .btnBox .btn {
  width: 35px;
  height: 100px;
  padding: 0;
  font-size: 30px;
  border-radius: 12px;
  border: none;
  background-color: #707070;
}
.servicesPage .contentData .dataInfo .btnBox .btn:disabled {
  opacity: 0;
}
.servicesPage .contentData .dataInfo .btnBox i {
  color: #fff;
}
.servicesPage .contentData .dataInfo .btnBox .prevBtn {
  transform: scaleX(-1);
  display: inline-block;
}
.servicesPage .contentData .case {
  width: 90%;
  margin: 16px auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  background-color: #fff;
  text-align: center;
  font-weight: 600;
}
.servicesPage .contentData .case .caseTitle {
  margin-top: 8px;
}
.servicesPage .contentData .case .caseText,
.servicesPage .contentData .case .caseUrl {
  margin-top: 12px;
}
.servicesPage .contentData .case .caseImg,
.servicesPage .contentData .case .servicesImg {
  aspect-ratio: 1/0.5;
}
.servicesPage .contentData .case .item + .item {
  margin-top: 0;
}
@media (width < 992px) {
  .servicesPage .contentData .case {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (width < 576px) {
  .servicesPage .contentData .case {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media (width > 768px) {
  .servicesPage .contentData {
    display: flex;
  }
  .servicesPage .contentData .btnBox {
    display: none;
  }
}

.phone,
.ipad {
  display: none;
}

@media (width < 769px) {
  .ipad {
    display: block;
  }
  .pc {
    display: none;
  }
}
@media (width < 576px) {
  .phone {
    display: block;
  }
}