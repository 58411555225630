@font-face {
    font-family: "NotoSansTC";
    src: local("NotoSansTC"), url("../fonts/NotoSansTC-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "BebasNeue";
    src: url("../fonts/BebasNeue-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: "NotoSansTC", sans-serif;
    font-size: 15px;
}
a {
    text-decoration: none;
}
.pageSettings {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.navbar {
    padding: 0;
    background-color: #000;
    .navbar-brand,
    .nav-link {
        &.active {
            color: #fff;
        }
        font-size: clamp(14px, 2vw, 18px);
        color: #fff;
        font-weight: 600;
    }
    .navbar-brand {
        display: flex;
        align-items: center;
        margin-right: 100px;
        font-size: clamp(20px, 3vw, 28px);
        font-weight: 400;
        font-family: "BebasNeue", sans-serif;
        img {
            width: 60px;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }
    @media (width < 576px) {
        .navbar-brand {
            margin-right: 0px;
        }
    }
}

.homeFooter,
.footer {
    min-height: 50px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #000;
    font-size: clamp(14px, 2vw, 18px);
    color: #fff;

    .ipad {
        width: 100%;
        position: relative;
        text-align: start;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        font-weight: 600;
        .footerLogo {
            height: 100%;
            position: absolute;
            right: 0;
            top: 18px;
            opacity: 0.5;
        }
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            img {
                width: 23px;
                height: 23px;
            }
        }
        .contactBox {
            padding: 0 15px 0;
            .phone {
                margin-bottom: 20px;
            }
        }
        .title {
            margin-bottom: 20px;
            font-size: 18px;
        }
    }
}
@media (width < 769px) {
    .homeFooter {
        min-height: auto;
        padding: 30px 16px;
    }
    .footer {
        padding: 0 12px;
        justify-content: start;
        font-size: clamp(12px, 2vw, 14px);
    }
}

.mainPage {
    flex: 1 0 auto;
    position: relative;
}

.homePage {
    .mainPage {
        min-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
    }
    .content-wrap {
        width: 85%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .content {
        text-align: center;
        font-weight: 600;
        .title {
            font-size: clamp(38px, 7vw, 68px);
            line-height: clamp(48px, 9vw, 88px);
        }
        .subtitle {
            margin-top: 32px;
            font-size: clamp(12px, 3vw, 20px);
            letter-spacing: -1px;
        }
        .contactBtn {
            width: 85%;
            padding: clamp(12px, 2vw, 14px);
            margin-top: 32px;
            position: relative;
            font-size: clamp(14px, 3vw, 20px);
            font-weight: 600;
            color: #fff;
            background-color: #000;
            border-radius: 50px;
            a {
                color: #fff;
            }
            i {
                position: absolute;
                right: 19%;
                top: 15%;
                font-size: 28px;
            }
        }
        .services,
        .case {
            padding: 50px 16px;
            .title {
                font-size: 26px;
            }
            .subtitle {
                margin-top: 10px;
            }
        }
        .services {
            background-size: contain;
            color: #fff;
            .contactBtn {
                width: 75%;
                background: transparent;
                border: 3px solid #fff;
                a {
                    color: #fff;
                }
                i {
                    right: 22%;
                }
            }
        }

        .grayBlock {
            height: 80px;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: end;
            background-color: #242a2e;
            color: #fff;
        }
    }
    .whiteBlock {
        height: 10px;
        background-color: #fff;
    }

    .toTop {
        justify-content: end;
        font-size: 18px;
    }
    @media (width < 576px) {
        .mainPage {
            margin-top: 10px;
        }
        .content {
            .contactBtn {
                i {
                    right: 12%;
                    top: 8%;
                }
            }

            .services {
                .contactBtn {
                    i {
                        right: 18%;
                    }
                }
            }
        }
    }
}
.content {
    .case {
        background-color: #fafafa;
        .item {
            padding: 16px 16px 24px;
            background-color: #f5f5f5;
            border-radius: 12px;
        }
        .item + .item {
            margin-top: 16px;
        }
        .titleBox {
            margin-bottom: 50px;
        }
        .caseImg,
        .ServicesImg {
            width: 100%;
            display: flex;
            aspect-ratio: 1/0.6;
            background-color: #fff;
            border-radius: 12px;
            .img {
                width: 90%;
                height: 90%;
                margin: auto;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .caseTitle {
            font-size: 24px;
            margin-top: 20px;
        }
        .caseText {
            margin-top: 20px;
            font-size: clamp(12px, 2vw, 14px);
        }
    }
}

.aboutPage,
.contactUsPage {
    .ipadBK {
        height: 25%;
    }
    .mainPage {
        padding: 30px 70px 30px 0px;
    }
    .content {
        height: 100%;
        position: relative;
        background-color: #f1f1f1;
    }
    .contactData {
        height: 100%;
        margin-left: clamp(0px, 12vw, 100px);
        display: flex;
        align-items: center;
        .title {
            margin-bottom: clamp(50px, 7vw, 60px);
            font-size: clamp(24px, 6vw, 50px);
            font-weight: 600;
            line-height: 1.2;
            word-break: keep-all;
        }
        .subtitle {
            margin-top: clamp(50px, 7vw, 60px);
            margin-bottom: clamp(50px, 7vw, 60px);
            font-size: clamp(16px, 3vw, 20px);
            font-weight: 600;
            word-break: keep-all;
            @media (width < 769px) {
                .titleBox {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .dataInfo {
            width: 60%;
        }
        .BKImg {
            width: 40%;
            height: 90%;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                right: -50px;
                object-fit: cover;
            }
        }
        .item {
            .ipad {
                display: inline-block;
            }
        }
        .textBox-wrap {
            .description {
                width: 100%;
            }
            .title {
                font-size: 28px;
                word-break: keep-all;
            }
            .textBox {
                line-height: 2;
            }
            @media (width < 769px) {
                .textBox {
                    margin-right: 0px;
                }
            }
        }
    }
    @media (width < 769px) {
        .mainPage {
            padding: 0;
        }
        .content {
            .contactData {
                height: auto;
                margin-left: 0;
                .title,
                .description {
                    margin-top: clamp(50px, 7vw, 60px);
                    text-align: center;
                }
            }
            .dataInfo {
                width: 100%;
                margin-bottom: 50px;
            }
            .BKImg {
                display: none;
            }
        }
    }
}
.contactUsPage {
    .mainPage {
        padding: 30px 0px 30px 70px;
    }
    .content {
        .ipadBK {
            .textBox {
                .text2 {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .contactData {
        margin-left: 0;
        .title {
            .en {
                white-space: nowrap;
            }
        }
        .BKImg {
            height: 55%;
            img {
                left: -90px;
                right: 0;
            }
        }
    }
    @media (width < 769px) {
        .mainPage {
            padding: 0;
        }
        .content {
            .contactData {
                margin-right: 0;
                text-align: center;
            }
        }
    }
}

.ipadBK {
    position: relative;
    height: 11%;
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .blackBlock {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #000;
        opacity: 0.4;
        z-index: 1;
    }
    .textBox {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        color: #fff;
        .text {
            margin-bottom: 5px;
            font-size: clamp(18px, 4vw, 28px);
            font-weight: 600;
        }
        .text2 {
            font-size: clamp(14px, 3vw, 18px);
        }
    }
}
.servicesPage {
    .content {
        height: 100%;
    }
    .contentData {
        height: inherit;
        .BKImg {
            width: 20%;
            min-width: 270px;
            height: inherit;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            .blackBlock {
                width: 100%;
                height: inherit;
                position: absolute;
                background-color: #000;
                opacity: 0.4;
                z-index: 0;
            }
            .textBox {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 10%;
                color: #fff;
                z-index: 1;
                white-space: nowrap;
                .title {
                    font-size: clamp(28px, 7vw, 58px);
                    font-weight: 600;
                }
                .text {
                    font-size: clamp(16px, 4vw, 22px);
                }
            }
        }
        .dataInfo {
            width: 80%;
            display: flex;
            @media (width < 769px) {
                width: 100%;
                margin-top: 24px;
                margin-bottom: 50px;
            }
            .btnBox {
                display: flex;
                align-items: center;
                padding: 8px;
                @media (width < 768px) {
                    display: none;
                }
                .btn {
                    &:disabled {
                        opacity: 0;
                    }
                    width: 35px;
                    height: 100px;
                    padding: 0;
                    font-size: 30px;
                    border-radius: 12px;
                    border: none;
                    background-color: #707070;
                }
                i {
                    color: #fff;
                }
                .prevBtn {
                    transform: scaleX(-1);
                    display: inline-block;
                }
            }
        }
        .case {
            width: 90%;
            margin: 16px auto;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 15px;
            background-color: #fff;
            text-align: center;
            font-weight: 600;
            .caseTitle {
                margin-top: 8px;
            }
            .caseText,
            .caseUrl {
                margin-top: 12px;
            }
            .caseImg,
            .servicesImg {
                aspect-ratio: 1/0.5;
            }
            .item + .item {
                margin-top: 0;
            }
            @media (width < 992px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media (width < 576px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
        @media (width > 768px) {
            display: flex;
            .btnBox {
                display: none;
            }
        }
    }
}

.phone,
.ipad {
    display: none;
}
@media (width < 769px) {
    .ipad {
        display: block;
    }
    .pc {
        display: none;
    }
}
@media (width < 576px) {
    .phone {
        display: block;
    }
}
